import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import KtLayout from '@solid-ui-blocks/KtLayout/KtLayout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import CanliDestek from '@solid-ui-blocks/CanliDestek/CanliDestek'

const BaskiOncesi = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)


  return (
    <KtLayout prp={props} cnt={content} lightmi={false} >
      <Seo title='Baski Öncesi Çalışmalarda Nelere Dikkat Edilmeli ' />
      <Divider space='5' />
      <h3 style={{ color: "black", textAlign: "center" }}>Baskı öncesi çalışmalarda nelere dikkat edilmelidir?</h3>
      <div style={{ marginLeft: "20%", marginRight: "20%" }} >
        <div style={{ color: "black", fontSize: "18px" }}>&nbsp;&nbsp;&nbsp;&nbsp;Bıçak izi işlemi için dikkat edilmesi gereken noktada hazırlanan taslağın, Kesim ve katlama yerlerindeki oluşabilecek sorunlara ilişkin bir boşluk bırakmaktır. Eğer boşluk bırakılmazsa kesilme işlemi sırasında oluşacak en ufak bir sorun bir ambalajın kullanılabilirlik durumunu yok edecektir. Bu yüzden bir grafik çalışması hazırlanırken, düzeltmelerin dikkatli bir şekilde hazırlanarak son değerlendirilmeler üzerine karar verilmesi gerekmektedir. Bu değerlendirme için profesyonel bir destek alabilirsiniz. Bıçak çizimine göre yapılacak ambalajın birebir örneğini kalıpçınızdan talep edebilirisiniz.Alınacak maket sonrasında tüm detaylar incelenir ve sorun oluşabilecek bir durum olmaması halinde erken müdahale etme şanşınız olur ve ciddi manada oluşabilecek zararlar engellenir. Tüm koşullar yerine getirildikten sonra grafik onayı alınır ve baskı tamamlanır.Hazırladığınız baskı için kullanılan vektörel çizim kalıp yapımı için bıçakçınıza gönderip kesme kalıbı talep edilir.</div>
      </div>
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <CanliDestek></CanliDestek>
    </KtLayout>
  )
}

export const query = graphql`
  query innerpageBaskiOncesiContent { 
    allBlockContent(filter: { page: { in: ["homepage/saas-v2", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    } 
  }
`

export default BaskiOncesi
